import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dashboard,
  PersonAddAlt,
  Save,
  Close,
  Edit,
  Add,
} from "@mui/icons-material";
import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import TextField from "@jumbo/components/JumboFormik/JumboTextField";
import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import { HOME, CUSTOMER } from "@jumbo/constants/routes";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  createCustomer,
  editCustomer,
  updateCustomer,
} from "app/redux/slice/customerSlice";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchema = yup.object({
  name: yup.string("").required("Please enter name."),
  email: yup.string("Enter your email").nullable().email("Enter a valid email"),
});

const AddAndUpdateCustomer = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: CUSTOMER,
      icon: <PersonAddAlt />,
      label: "Customer",
    },
    {
      icon: id ? <Edit /> : <Add />,
      label: id ? "Update Customer" : "Add Customer",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_no: "",
      store_id: null,
      gst_no: "",
      pan_no: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newCustomerData = {
        name: values.name?.trim(),
        email:
          values.email?.trim() !== ""
            ? values.email?.trim()?.toLowerCase()
            : null,
        phone_no:
          values.phone_no?.trim() !== "" ? values.phone_no?.trim() : null,
        pan_no: values.pan_no?.trim() !== "" ? values.pan_no?.trim()?.toUpperCase() : null,
        gst_no: values.gst_no?.trim() !== "" ? values.gst_no?.trim()?.toUpperCase() : null,
        address: values.address?.trim() !== "" ? values.address?.trim() : null,
        store_id: localStorage.getItem("store_id"),
      };

      const response = await dispatch(
        id
          ? updateCustomer({ id, data: newCustomerData })
          : createCustomer(newCustomerData)
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(CUSTOMER);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(editCustomer(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            const customerDetail = response?.data?.data?.customer_detail;
            setFieldValue("name", customerDetail?.name);
            setFieldValue("email", customerDetail?.email);
            setFieldValue("phone_no", customerDetail?.phone_no);
            setFieldValue("pan_no", customerDetail?.pan_no);
            setFieldValue("gst_no", customerDetail?.gst_no);
            setFieldValue("address", customerDetail?.address);
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      <PageContainer
        heading={id ? "Update Customer" : "Add Customer"}
        breadcrumb={breadcrumbData}
      >
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"Name"} name="name" fullWidth required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"Email"} name="email" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"Mobile"} name="phone_no" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"PAN No"} name="pan_no" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"GST No"} name="gst_no" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"Address"} name="address" fullWidth />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <JumboAutoComplete
                      options={storeData.list ?? []}
                      label="Store*"
                      size="large"
                      getOptionLabel={(option) => option.name}
                      name="store_id"
                      onChange={(evt, value) =>
                        setFieldValue("store_id", value?.id)
                      }
                    />
                </Grid> */}
                </Grid>

                <Grid item sm={12} className={classes?.buttonContainer}>
                  <LoadingButton
                    buttonLabel={id ? "Update" : "Save"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                    loading={isSubmitting || mutation.isLoading}
                    type="submit"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(CUSTOMER)}
                  />
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default AddAndUpdateCustomer;
