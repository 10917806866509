import React from "react";
import PropTypes from "prop-types";

import { TableBody, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme?.palette?.common?.dark,
  },
  iconButton: {
    padding: 3,
  },
  ellipsis: {
    display: "block",
    width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionTd: {
    whiteSpace: "nowrap",
  },
  fontBold: {
    fontWeight: 900,
  },
  noDataRow: {
    margin: "0 auto",
  },
}));

const TableItem = ({
  rows,
  columns,
  scroll,
  isInvoice,
  isBill,
  ledgerRow,
  isCashSell,
  isLedger = false,
}) => {
  const classes = useStyles();
  let amount = 0;
  let dueAmount = 0;
  let receivedAmount = 0;
  let debitTotal = 0;
  let creditTotal = 0;

  const calculateBillTotal = (row) => {
    amount += row?.bill_amount ?? 0;
    dueAmount += row?.pending_amount ?? 0;
    receivedAmount += row?.paid_amount ?? 0;
  };
  const calculateInvoiceTotal = (row) => {
    amount += isCashSell
      ? row?.cash_sell_amount ?? 0
      : row?.invoice_amount ?? 0;
    dueAmount += row?.pending_amount ?? 0;
    receivedAmount += row?.received_amount ?? 0;
  };
  const calculateEntryBookTotal = (row) => {
    debitTotal += row?.debit;
    creditTotal += row?.credit;
  };

  return (
    <TableBody>
      {!rows?.length ? (
        <TableRow className={classes?.noDataRow}>
          <TableCell colSpan={12} align="center">
            No Data Found
          </TableCell>
        </TableRow>
      ) : (
        <>
          {rows?.length &&
            rows.map((data, index) => {
              if (isInvoice) calculateInvoiceTotal(data);
              if (isBill) calculateBillTotal(data);
              if (isLedger) calculateEntryBookTotal(data);
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={data.name}
                  sx={{ cursor: "pointer" }}
                >
                  {columns?.length &&
                    columns.map((cell, i) => (
                      <React.Fragment key={i}>
                        {!cell?.hide && (
                          <>
                            <TableCell
                              key={i}
                              sx={{
                                color:
                                  isBill || isInvoice
                                    ? data?.due_date
                                      ? moment(new Date()).isAfter(
                                          data?.due_date
                                        )
                                        ? "red"
                                        : "inherit"
                                      : "inherit"
                                    : "inherit",
                              }}
                            >
                              {cell?.image ? (
                                <></>
                              ) : (
                                <div
                                  className={
                                    cell.ellipsis
                                      ? classes.ellipsis
                                      : scroll
                                      ? classes.actionTd
                                      : ""
                                  }
                                >
                                  {cell.render
                                    ? cell.render(data, index)
                                    : data[cell?.id]}
                                </div>
                              )}
                            </TableCell>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </TableRow>
              );
            })}
          {isLedger && (
            <>
              <TableRow>
                <TableCell>
                  {ledgerRow[0]?.type === "debit"
                    ? moment(ledgerRow[0]?.date).format("DD/MM/yyyy")
                    : ""}
                </TableCell>
                <TableCell>
                  {ledgerRow[0]?.type === "debit"
                    ? ledgerRow[0]?.description
                    : ""}
                </TableCell>
                <TableCell>
                  {ledgerRow[0]?.type === "debit" ? ledgerRow[0]?.amount : ""}
                </TableCell>
                <TableCell>
                  {ledgerRow[0]?.type === "credit"
                    ? moment(ledgerRow[0]?.date).format("DD/MM/yyyy")
                    : ""}
                </TableCell>
                <TableCell>
                  {ledgerRow[0]?.type === "credit"
                    ? ledgerRow[0]?.description
                    : ""}
                </TableCell>
                <TableCell>
                  {ledgerRow[0]?.type === "credit" ? ledgerRow[0]?.amount : ""}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 600 }}>
                  TOTAL
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {ledgerRow[0]?.type === "debit"
                    ? +ledgerRow[0]?.amount + +debitTotal
                    : debitTotal}
                </TableCell>
                <TableCell colSpan={2} sx={{ fontWeight: 600 }}>
                  TOTAL
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {ledgerRow[0]?.type === "credit"
                    ? +ledgerRow[0]?.amount + +creditTotal
                    : creditTotal}
                </TableCell>
              </TableRow>
            </>
          )}
          {(isInvoice || isBill) && (
            <>
              <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
                <TableCell colSpan={3} sx={{ fontWeight: 600 }}>
                  TOTAL
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Math.round((amount ?? 0) * 100) / 100}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Math.round((receivedAmount ?? 0) * 100) / 100}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Math.round((dueAmount ?? 0) * 100) / 100}
                </TableCell>
                <TableCell />
              </TableRow>
            </>
          )}
        </>
      )}
    </TableBody>
  );
};

TableItem.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  scroll: PropTypes.bool,
};

TableItem.defaultProps = {
  scroll: false,
};

export default TableItem;
