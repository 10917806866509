import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { getAssetPath } from "app/utils/appHelpers";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { HOME } from "@jumbo/constants/routes";

const Error401 = () => {
  const navigate = useNavigate();

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ display: "inline-flex", mb: 3 }}>
        <img
          src={getAssetPath(`${ASSET_IMAGES}/401.svg`, "380x206")}
          alt="401"
          width={380}
        />
      </Div>
      <Button variant="contained" onClick={() => navigate(HOME)}>
        Go to home
      </Button>
    </Div>
  );
};

export default Error401;
