import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getCustomerList = createAsyncThunk(
  "get-customer",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("customer/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createCustomer = createAsyncThunk(
  "create-customer",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("customer", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editCustomer = createAsyncThunk(
  "edit-customer",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`customer/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "update-customer",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/customer/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "delete-customer",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/customer/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.customer_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getCustomerList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default customerSlice.reducer;
