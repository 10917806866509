import React from "react";
import PropTypes from "prop-types";

import { Box, Table, TableContainer, TablePagination } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const JumboTable = ({
  size,
  columns,
  order,
  orderBy,
  onRequestSort,
  rows,
  scroll,
  rowsPerPage,
  page,
  count,
  setRowsPerPage,
  setPage,
  ledgerRow,
  isLedger = false,
  isInvoice = false,
  isCashSell = false,
  isBill = false,
}) => {
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={size}>
          <TableHeading
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
          />
          <TableItem
            columns={columns}
            rows={rows}
            scroll={scroll}
            isInvoice={isInvoice}
            isCashSell={isCashSell}
            isBill={isBill}
            isLedger={isLedger}
            ledgerRow={ledgerRow}
          />
        </Table>
      </TableContainer>
      {rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

JumboTable.propTypes = {
  size: PropTypes.string,
  columns: PropTypes.array,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  rows: PropTypes.array,
  scroll: PropTypes.bool,
  page: PropTypes.number,
  setRowsPerPage: PropTypes.func,
};

JumboTable.defaultProps = {
  medium: "medium",
  page: 0,
  isShowPagination: false,
};

export default JumboTable;
