import React, { useEffect, useRef, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import { Dashboard, ViewHeadline } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PageContainer from "@jumbo/components/PageContainer";
import Table from "@jumbo/components/JumboTable";

import { HOME } from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { getPartiList } from "app/redux/slice/partiSlice";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { getLedgerList } from "app/redux/slice/entrybookSlice";
import NoDataPlaceholder from "app/shared/NoDataPlaceholder";
import { getCustomerList } from "app/redux/slice/customerSlice";
import JumboSelectBox from "@jumbo/components/JumboSelectBox";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "20px",
  },
  dropDownContainer: {
    padding: "20px 12px 0px 12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <ViewHeadline />,
    label: "Ledger",
  },
];

const typeOfData = [
  { id: "supplier", label: "Vendor" },
  { id: "customer", label: "Customer" },
];
const validationSchema = yup.object().shape({
  // supplier_id: yup.string().required("Please select vendor."),
  // customer_id: yup.string().required("Please select customer."),
  type: yup
    .string()
    .oneOf(["customer", "supplier"])
    .required("Type is required"),
  supplier_id: yup.string().when("type", {
    is: "supplier",
    then: (schema) => schema.required("Please select vendor."),
    otherwise: (schema) => schema.notRequired(),
  }),
  customer_id: yup.string().when("type", {
    is: "customer",
    then: (schema) => schema.required("Please select customer."),
    otherwise: (schema) => schema.notRequired(),
  }),
  toDate: yup.date().when("fromDate", (fromDate, schema) => {
    if (fromDate) {
      return schema
        .required("To Date is required")
        .min(fromDate, "To Date cannot be before From Date");
    }
    return schema;
  }),
});

const Ledger = () => {
  const ledgerRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const supplierData = useSelector((state) => state?.supplier);
  const customerData = useSelector((state) => state?.customer);
  const [supplier, setSupplier] = useState();
  const [customer, setCustomer] = useState();
  const [view, setView] = useState();
  const [closingBalance, setClosingBalance] = useState([]);
  const [data, setData] = useState([]);

  const column = [
    {
      id: "date",
      label: "Date",
      render: (elm) => (
        <>
          {elm?.debit_date ? moment(elm?.debit_date).format("DD/MM/YYYY") : ""}
        </>
      ),
    },
    { id: "debit_des", label: "Note" },
    { id: "debit", label: "Debit" },
    {
      id: "date",
      label: "Date",
      render: (elm) => (
        <>
          {elm?.credit_date
            ? moment(elm?.credit_date).format("DD/MM/YYYY")
            : ""}
        </>
      ),
    },
    { id: "credit_des", label: "Note" },
    { id: "credit", label: "Credit" },
  ];

  const getData = async () => {
    const value = {
      from_date: values?.fromDate,
      to_date: values?.toDate,
      type: values?.type,
    };
    const response = await dispatch(
      getLedgerList({
        id: values?.type === "customer" ? customer?.id : supplier?.id,
        data: value,
      })
    ).unwrap();
    if (response.status === 200) {
      const tempObj = response.data?.data?.ledger;
      const greater =
        tempObj?.debit?.length > tempObj?.credit?.length
          ? tempObj?.debit.length
          : tempObj?.credit.length;
      let tempArr = [];
      setClosingBalance(tempObj?.closing_balance);

      for (let i = 0; i <= greater - 1; i++) {
        let temp = {};
        temp.debit_date = tempObj?.debit[i] ? tempObj?.debit[i].date : "";
        temp.debit_des = tempObj?.debit[i] ? tempObj?.debit[i].description : "";
        temp.debit = tempObj?.debit[i] ? tempObj?.debit[i].amount : "";

        temp.credit_date = tempObj?.credit[i] ? tempObj?.credit[i].date : "";
        temp.credit_des = tempObj?.credit[i]
          ? tempObj?.credit[i].description
          : "";
        temp.credit = tempObj?.credit[i] ? tempObj?.credit[i].amount : "";

        tempArr.push(temp);
      }
      setView(true);
      setData(tempArr);
    } else {
      enqueueSnackbar(response.data?.response_message, {
        variant: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      type: "supplier",
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: getData,
  });

  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      dispatch(getCustomerList({ store_id: localStorage.getItem("store_id") }));
      dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
    } else {
      dispatch(getCustomerList());
      dispatch(getPartiList());
    }
  }, [dispatch]);

  const downloadPdf = () => {
    const input = ledgerRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("ledger.pdf");
    });
  };
  const { setFieldValue, errors, values, handleSubmit } = formik;

  return (
    <PageContainer heading="Ledger" breadcrumb={breadcrumbData}>
      <Paper
        className={
          supplier?.id && view && data.length !== 0
            ? ""
            : classes?.filterContainer
        }
      >
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <JumboSelectBox
                        data={typeOfData}
                        id="id"
                        name={"type"}
                        size="small"
                        labelKey="label"
                        valueKey="id"
                        label="Type*"
                        required
                        value={values?.type}
                        onChange={(evt) => {
                          setFieldValue("type", evt.target.value);
                          setView(false);
                        }}
                      />
                    </Grid>
                    {values?.type === "supplier" && (
                      <Grid item xs={12} sm={3}>
                        <JumboAutoComplete
                          options={supplierData.list ?? []}
                          label="Vendor*"
                          size="small"
                          getOptionLabel={(option) => option.name}
                          name="supplier_id"
                          onChange={(evt, value) => {
                            setSupplier(value);
                            if (!value) setView(false);
                            setFieldValue("supplier_id", value?.id);
                          }}
                          error={errors?.supplier_id}
                          helperText={errors?.supplier_id}
                        />
                      </Grid>
                    )}
                    {values?.type === "customer" && (
                      <Grid item xs={12} sm={3}>
                        <JumboAutoComplete
                          options={customerData.list ?? []}
                          label="Customer*"
                          size="small"
                          getOptionLabel={(option) => option.name}
                          name="customer_id"
                          onChange={(evt, value) => {
                            setCustomer(value);
                            if (!value) setView(false);
                            setFieldValue("customer_id", value?.id);
                          }}
                          error={errors?.customer_id}
                          helperText={errors?.customer_id}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"From Date"}
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"To Date"}
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.toDate}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      {supplier?.id && view && data.length !== 0 ? (
        <Button onClick={downloadPdf}>Download PDF</Button>
      ) : (
        <></>
      )}

      <Paper>
        {view &&
          (data.length !== 0 ? (
            <div ref={ledgerRef} id="ledger-section">
              <Card>
                <Typography variant={"h6"} p={1} sx={{ textAlign: "center" }}>
                  {values.fromDate && values.toDate
                    ? `Ledger of ${
                        values?.type === "customer"
                          ? customer?.name
                          : supplier?.name
                      } (${values.fromDate} - ${values.toDate})`
                    : `Ledger of ${
                        values?.type === "customer"
                          ? customer?.name
                          : supplier?.name
                      }`}
                </Typography>
                <Table
                  columns={column}
                  rows={data}
                  count={data.length}
                  ledgerRow={closingBalance}
                  isLedger
                />
              </Card>
            </div>
          ) : (
            <NoDataPlaceholder />
          ))}
      </Paper>
    </PageContainer>
  );
};

export default Ledger;
