import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  descriptionList:[],
  perPage: "",
  total: 0,
  loading: false,
};

export const getBillList = createAsyncThunk(
  "get-bill",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("bill/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);
export const getBillUniqDescriptionList = createAsyncThunk(
  "get-bill-uniq-description",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("bill/uniq-description/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createBill = createAsyncThunk(
  "create-bill",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("bill", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editBill = createAsyncThunk(
  "edit-bill",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`bill/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateBill = createAsyncThunk(
  "update-bill",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/bill/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteBill = createAsyncThunk(
  "delete-bill",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/bill/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteBillItem = createAsyncThunk(
  "delete-bill-item",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/bill/item/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);


const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.bill_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getBillList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      })
      .addCase(getBillUniqDescriptionList.fulfilled, (state, action) => {
        state.descriptionList = action?.payload?.data?.data?.item_list;
      });
  },
});

export default billSlice.reducer;
