import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  loading: false,
  errorMessage: "",
  loggedUser: {},
  token: "",
};

export const loginUser = createAsyncThunk(
  "authenticate/login",
  async (credentials, { dispatch }) => {
    try {
      const response = await axios.post("auth/login", credentials);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authenticate/reset-password",
  async (credentials, { dispatch }) => {
    try {
      const response = await axios.post("auth/reset-password", credentials);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const setPassword = createAsyncThunk(
  "authenticate/set-password",
  async (credentials, { dispatch }) => {
    try {
      const response = await axios.post("auth/set-password", credentials);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "authenticate/logout",
  async (id, { dispatch }) => {
    try {
      const response = await axios.post(`auth/logout/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const changePassword = createAsyncThunk(
  "authenticate/change-password",
  async (credentials, { dispatch }) => {
    try {
      const response = await axios.post("auth/change-password", credentials);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editProfile = createAsyncThunk(
  "authenticate/edit-profile",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/user/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        state.loggedUser = action?.payload?.data?.data?.user;
        state.token = action?.payload?.data?.data?.token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        state.loggedUser = {};
        state.token = "";
        state.email = "";
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedUser = action?.payload?.data?.data?.user;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });
  },
});

export default authSlice.reducer;
