import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  errorMessage: "",
};

export const fetchError = createAsyncThunk("fetchError", async (message) => {
  return message.data?.response_message;
});

const authSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchError.pending, (state) => {
        state.errorMessage = "";
      })
      .addCase(fetchError.fulfilled, (state, action) => {
        state.errorMessage = action.payload;
      })
      .addCase(fetchError.rejected, (state, action) => {
        state.errorMessage = action.payload;
      });
  },
});

export default authSlice.reducer;
