import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import {
  Dashboard,
  Receipt,
  Add,
  Edit,
  Delete,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import PageContainer from "@jumbo/components/PageContainer";
import Table from "@jumbo/components/JumboTable";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import {
  HOME,
  CASH_SELL_ADD,
  createCashSellEditRoute,
} from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { userType } from "app/helpers/userType";
import { getCustomerList } from "app/redux/slice/customerSlice";
import { deleteCashSell, getCashSellList } from "app/redux/slice/cashSellSlice";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "20px",
  },
  dropDownContainer: {
    padding: "20px 12px 0px 12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <Receipt />,
    label: "Cash Sell",
  },
];

const validationSchema = yup.object().shape({
  toDate: yup.date().when("fromDate", (fromDate, schema) => {
    if (fromDate) {
      return schema
        .required("To Date is required")
        .min(fromDate, "To Date cannot be before From Date");
    }
    return schema;
  }),
});
const CashSell = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const deleteDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { isMA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const customerData = useSelector((state) => state?.customer);
  const cashSellData = useSelector((state) => state?.cashSell);
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [customer, setCustomer] = useState();
  const [view, setView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );

  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });

  const getPermission = () => {
    if (isMA) {
      setPermission({
        createPermission: true,
        updatePermission: true,
        deletePermission: true,
      });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      permissionData?.length &&
        permissionData.forEach(function (num) {
          if (num?.name === "cashSell-create") {
            permissionValue.createPermission = true;
          }
          if (num?.name === "cashSell-delete") {
            permissionValue.deletePermission = true;
          }
          if (num?.name === "cashSell-update") {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const column = [
    {
      id: "date",
      label: "Date",
      render: (elm) => <> {moment(elm?.cash_sell_date).format("DD/MM/YYYY")}</>,
    },
    { id: "cash_sell_number", label: "Slip No" },
    { label: "Customer", render: (elm) => elm?.customer?.name },
    { id: "cash_sell_amount", label: "Amount" },
    { id: "received_amount", label: "Received Amount" },
    { id: "pending_amount", label: "Due Amount" },
    {
      label: "Actions",
      key: "actionIcon",
      sort: false,
      render: (elm) => (
        <>
          {permission.updatePermission && (
            <IconButton
              onClick={() => {
                navigate(createCashSellEditRoute(elm?.id));
              }}
            >
              <Edit />
            </IconButton>
          )}
          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                deleteDisclosure.onOpen();
              }}
            >
              <Delete color={"error"} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      search: values?.search?.trim(),
      from_date: values?.fromDate,
      to_date: values?.toDate,
    };
    if (customer) value.customer_id = customer.id;
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    )
      value.store_id = localStorage.getItem("store_id");
    dispatch(getCashSellList(value));
  };

  const handleConfirm = () => {
    dispatch(deleteCashSell(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          getData();
          deleteDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
  };
  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      search: "",
      type: "all",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: getData,
  });
  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      getData();
    } else {
      setView(false);
      enqueueSnackbar("Please select store.", { variant: "error" });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch]);

  useEffect(() => {
    dispatch(getCustomerList({store_id:localStorage.getItem("store_id")}));
  }, [dispatch]);
  const { values, handleSubmit } = formik;

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : (
        <PageContainer heading="Cash Sell" breadcrumb={breadcrumbData}>
          <Paper className={classes?.filterContainer}>
            <Card sx={{ display: "flex" }}>
              <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
                <CardContent>
                  <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <JumboTextField
                            fullWidth
                            size="small"
                            name="search"
                            label="Search"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <JumboAutoComplete
                            options={customerData.list ?? []}
                            label="Customer"
                            size="small"
                            getOptionLabel={(option) => option.name}
                            name="customer"
                            onChange={(evt, value) => setCustomer(value)}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={2}>
                      <JumboSelectBox
                        data={typeData}
                        id="id"
                        name={"type"}
                        size="small"
                        labelKey="label"
                        valueKey="id"
                        label="Type"
                        required
                        value={values?.type}
                        onChange={(evt) =>
                          setFieldValue("type", evt.target.value)
                        }
                      />
                    </Grid> */}
                        <Grid item xs={12} sm={2}>
                          <JumboTextField
                            type="date"
                            label={"From Date"}
                            name="fromDate"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            value={values?.fromDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <JumboTextField
                            type="date"
                            label={"To Date"}
                            name="toDate"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            value={values?.toDate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            onClick={() => {
                              setPage(0);
                              handleSubmit();
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </FormikProvider>
                </CardContent>
              </Div>
            </Card>
          </Paper>
          {view && (
            <Paper>
              {permission.createPermission && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    className={classes?.buttonPadding}
                    onClick={() => navigate(CASH_SELL_ADD)}
                  >
                    Add
                  </Button>
                </Box>
              )}
              <Table
                columns={column}
                rows={cashSellData.list}
                count={cashSellData.total}
                setPage={setPage}
                page={page}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                isInvoice
                isCashSell
              />
            </Paper>
          )}

          <DialogConfirm
            open={deleteDisclosure?.isOpen}
            title="Delete Cash Sell"
            message="Are you sure you want to delete this Cash Sell ?"
            onConfirm={handleConfirm}
            onClose={deleteDisclosure.onClose}
          />
        </PageContainer>
      )}
    </>
  );
};

export default CashSell;
