import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
const url = process.env.REACT_APP_OM_URL;

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const common = useSelector((state) => state?.common);
  const authUser = useSelector((state) => state?.auth?.loggedUser);

  useEffect(() => {
    if (
      common.errorMessage === "Token Expired." ||
      common.errorMessage === "Token not passed." ||
      common.errorMessage === "Invalid token." ||
      common.errorMessage === "Your token has expired!"
    ) {
      // dispatch(logoutUser(authUser?._id))
      //   .unwrap()
      //   .then((response) => {
      //     if (response.status === 200) {
      //       localStorage.clear();
      //       navigate(LOGIN);
      //     }
      //   });
      window.location.href = `${url}login`;
    }
  }, [authUser?._id, common, dispatch, navigate]);

  const appRoutes = useJumboRoutes(routes);

  return <React.Fragment>{appRoutes}</React.Fragment>;
};
export default AppRoutes;
