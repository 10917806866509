import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: {},
  loading: false,
};

export const getDashboard = createAsyncThunk(
  "get-dashboard",
  async (_, { dispatch }) => {
    try {
      const response = await axios.get("dashboard");
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.dashboardList;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default dashboardSlice.reducer;
