import React, { lazy } from "react";
import dashboardRoutes from "./dashboardRoutes";
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "@jumbo/constants/routes";
import Checkpost from "app/pages/auth/Checkpost";

const Page = lazy(() => import("@jumbo/shared/Page"));
const Loin = lazy(() => import("../pages/auth/login"));
const ForgoPassword = lazy(() => import("../pages/auth/forgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/resetPassword"));
// const Error404 = lazy(() => import("../pages/Error404"));
const url = process.env.REACT_APP_OM_URL;

const RedirectToExternal = () => {
  window.location.href = `${url}dashboard`;
  return null; // Return null to prevent rendering anything on the page
};
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: LOGIN,
    element: <Page component={Loin} layout={"solo-page"} />,
  },
  {
    path: FORGOT_PASSWORD,
    element: <Page component={ForgoPassword} layout={"solo-page"} />,
  },
  {
    path: RESET_PASSWORD,
    element: <Page component={ResetPassword} layout={"solo-page"} />,
  },
  {
    path: "*",
    element:  <RedirectToExternal />,
  },
  {
    path: "/checkpost/:token/:route",
    element: <Page component={Checkpost} layout={"solo-page"} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = dashboardRoutes;

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
