
export const userType = () => {
  let obj = { isMA: false };
  const result = localStorage.getItem('isAdmin');
  
  if (result === 'true') {
    obj.isMA = true;
  }
  return obj;
};
