import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
const url = process.env.REACT_APP_OM_URL_CHECK;

const Checkpost = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const { token, route } = useParams();
  useEffect(() => {
    if (token) {
      fetch(`${url}api/jwt/verification`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const { permissions, user } = data;
          if (user) {
            localStorage.setItem("store_id", user?.store_id ?? 0);
            localStorage.setItem("isAdmin", user?.store_id ? false : true);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("jwt_token", user?.jwt_token);
            setCookie("access_token", user?.jwt_token, {
              path: "/",
            });
          }
          if (permissions) {
            localStorage.setItem("permission", JSON.stringify(permissions));
            navigate(`/${route}`);
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("There was a problem with the fetch operation:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <Box
      className="login-wrapper"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size="3rem" />
    </Box>
  );
};

export default Checkpost;
