import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const JumboSelectBox = (props) => {
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    isRequired,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    label,
    ...rest
  } = props;

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      className={"form-control"}
    >
      {label && (
        <InputLabel
          error={error || helperText !== ""}
          id={"label-" + id || name}
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...rest}
        name={name}
        labelId={"label-" + id || name}
        id={id || name}
        value={value}
        onChange={onChange}
        label={label ? (isRequired ? label + " *" : label) : null}
        error={error || helperText !== ""}
        displayEmpty={displayEmpty}
      >
        {placeholder && displayEmpty && (
          <MenuItem value="">{placeholder}</MenuItem>
        )}
        {data?.length ? (
          data.map((item, index) =>
            renderRow ? (
              renderRow(item, index)
            ) : (
              <MenuItem key={index} value={valueKey ? item[valueKey] : item}>
                {item[labelKey]}
              </MenuItem>
            )
          )
        ) : (
          <MenuItem key={"1"} value={"no data"} disabled>
            No data found
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={error || helperText !== ""}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

JumboSelectBox.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};

JumboSelectBox.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  isRequired: false,
  size: "large",
  error: false,
  helperText: "",
  onChange: () => {},
};

export default JumboSelectBox;
