import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "../slice/authSlice";
import commonSlice from "../slice/commonSlice";
import roleSlice from "../slice/roleSlice";
import partiSlice from "../slice/partiSlice";
import invoiceSlice from "../slice/invoiceSlice";
import entrybookSlice from "../slice/entrybookSlice";
import billSlice from "../slice/billSlice";
import portfolioSlice from "../slice/portfolioSlice";
import dashboardSlice from "../slice/dashboardSlice";
import storeSlice from "../slice/storeSlice";
import onAccountSlice from "../slice/onAccountSlice";
import expenseTypeSlice from "../slice/expenseTypeSlice";
import logSlice from "../slice/logSlice";
import customerSlice from "../slice/customerSlice";
import categorySlice from "../slice/categorySlice";
import productSlice from "../slice/productSlice";
import vendorSlice from "../slice/vendorSlice";
import cashSellSlice from "../slice/cashSellSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  common: commonSlice,
  role: roleSlice,
  supplier: partiSlice,
  store: storeSlice,
  log: logSlice,
  onAccount: onAccountSlice,
  expenseType: expenseTypeSlice,
  invoice: invoiceSlice,
  entryBook: entrybookSlice,
  bill: billSlice,
  portfolio: portfolioSlice,
  dashboard: dashboardSlice,
  customer: customerSlice,
  category: categorySlice,
  product: productSlice,
  vendor: vendorSlice,
  cashSell: cashSellSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

export const persister = persistStore(store);
