import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard, PersonAddAlt, Save, Close, Add } from "@mui/icons-material";
import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import TextField from "@jumbo/components/JumboFormik/JumboTextField";
import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import { HOME, ON_ACCOUNT } from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { createOnAccount } from "app/redux/slice/onAccountSlice";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { getStoreList } from "app/redux/slice/storeSlice";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchema = yup.object({
  store_id: yup.string("").required("Please enter store_id."),
  amount: yup.number("").nullable().required("Please enter amount.").min(1),
  transaction_date: yup
    .date()
    .nullable()
    .required("Please select transaction date."),
});

const AddOnAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const storeData = useSelector((state) => state?.store);

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: ON_ACCOUNT,
      icon: <PersonAddAlt />,
      label: "OnAccount",
    },
    {
      icon: <Add />,
      label: "Add OnAccount",
    },
  ];

  const formik = useFormik({
    initialValues: {
      store_id: null,
      amount: null,
      note: "",
      transaction_date: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newOnAccountData = {
        store_id: values.store_id,
        amount: values.amount,
        transaction_date: values.transaction_date,
        note: values.note?.trim(),
      };
      const response = await dispatch(createOnAccount(newOnAccountData)).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(ON_ACCOUNT);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getStoreList());
  }, [dispatch]);

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;

  return (
    <>
      <PageContainer heading={"Add OnAccount"} breadcrumb={breadcrumbData}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <JumboAutoComplete
                      options={storeData.list ?? []}
                      label="Store*"
                      size="large"
                      getOptionLabel={(option) => option.name}
                      name="store_id"
                      onChange={(evt, value) =>
                        setFieldValue("store_id", value?.id)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <JumboTextField
                      type="date"
                      label={"Transaction Date"}
                      name="transaction_date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      value={values?.transaction_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      type="number"
                      label={"Amount*"}
                      name="amount"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField label={"Note"} name="note" fullWidth />
                  </Grid>
                </Grid>
                <Grid item sm={12} className={classes?.buttonContainer}>
                  <LoadingButton
                    buttonLabel={"Save"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                    loading={isSubmitting || mutation.isLoading}
                    type="submit"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(ON_ACCOUNT)}
                  />
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default AddOnAccount;
