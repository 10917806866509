import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  loading: false,
};

export const getStoreList = createAsyncThunk(
  "get-store",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("store/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateStoreBalance = createAsyncThunk(
  "update-store-store",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`store/balance/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoreList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data;
      })
      .addCase(getStoreList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default storeSlice.reducer;
