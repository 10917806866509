import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getRoleList = createAsyncThunk(
  "get-role",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("role/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteRole = createAsyncThunk(
  "delete-role",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/role/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createRole = createAsyncThunk(
  "create-role",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("role", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editRole = createAsyncThunk(
  "edit-role",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`role/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateRole = createAsyncThunk(
  "update-role",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/role/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getPermissionList = createAsyncThunk(
  "get-permission",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("permission/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getAllowedPermissions = createAsyncThunk(
  "edit-permission",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`permission/assigned-permission/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updatePermissions = createAsyncThunk(
  "update-permission",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("permission/assigned-permission", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.roleList;
        state.perPage = action?.payload?.data?.data?.perPage;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getRoleList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default roleSlice.reducer;
