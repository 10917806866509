import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userType } from 'app/helpers/userType';
const url = process.env.REACT_APP_OM_URL;

function PrivateRoute({ children, permission }) {
  const result = JSON.parse(localStorage.getItem('user'));
  const { isMA } = userType();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!result) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return; // No need to continue rendering
    }
    
    if (isMA) {
      setLoader(true);
    } else {
      if (permission) {
        if (Array.isArray(JSON.parse(localStorage.getItem('permission')))) {
          const hasPermission = JSON.parse(localStorage.getItem('permission')).some(
            (num) => num?.name === permission,
          );
          console.log({hasPermission});
          
          setLoader(hasPermission);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, JSON.parse(localStorage.getItem('permission')), pathname, requestedLocation]);

  if (!result) {
    window.location.href = `${url}login`;
    // return <Navigate to={url} />;
  }
  if (!loader) {
    return <Navigate to="/401" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
