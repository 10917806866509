import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

import DialogBase from "@jumbo/components/JumboDialog/components/DialogBase";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import { createExpenseType, editExpenseType, updateExpenseType } from "app/redux/slice/expenseTypeSlice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const UpdateAddExpenseType = ({ expenseTypeDisclosure, setId, id, getData }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter name."),
    description: Yup.string().required("Please enter description."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      const newExpenseTypeData = {
        name: values.name?.trim(),
        description: values.description?.trim(),
      };
      const response = await dispatch(
        id ? updateExpenseType({ id, data: newExpenseTypeData }) : createExpenseType(newExpenseTypeData)
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        expenseTypeDisclosure.onClose();
        resetForm();
        setId(false);
        getData();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });

  const onClose = () => {
    expenseTypeDisclosure.onClose();
    formik.resetForm();
    setId(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(editExpenseType(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            setFieldValue("name", response?.data?.data?.existing_expense?.name);
            setFieldValue(
              "description",
              response?.data?.data?.existing_expense?.description
            );
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  const { setFieldValue } = formik;

  const getContent = () => {
    return (
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <JumboAppTextField fullWidth name="name" label="Name*" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <JumboAppTextField
                fullWidth
                name="description"
                label="Description*"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    );
  };

  return (
    <DialogBase
      open={expenseTypeDisclosure?.isOpen}
      onClose={onClose}
      title={id ? "Update Expense Type" : "Add Expense Type"}
      content={getContent()}
      onSubmit={formik.handleSubmit}
      onCancel={onClose}
      buttonLabel={{ confirm: id ? "Update" : "Add", cancel: "Cancel" }}
    />
  );
};

export default UpdateAddExpenseType;
