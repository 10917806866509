import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  lastBillNo:'',
  lastInvoiceNo:'',
  loading: false,
};

export const getInvoiceList = createAsyncThunk(
  "get-invoice",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("invoice/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getInvoiceNo = createAsyncThunk(
  "get-invoice-no",
  async (_, { dispatch }) => {
    try {
      const response = await axios.get("invoice/get-number");
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createInvoice = createAsyncThunk(
  "create-invoice",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("invoice", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editInvoice = createAsyncThunk(
  "edit-invoice",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`invoice/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  "download-invoice",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`invoice/download/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getLastInvoiceAndBillNo = createAsyncThunk(
  "get-invoice-no",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`invoice/latest-number/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "update-invoice",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/invoice/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "delete-invoice",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/invoice/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteInvoiceItem = createAsyncThunk(
  "delete-invoice-item",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/invoice/item/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.invoice_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
      builder
      .addCase(getLastInvoiceAndBillNo.fulfilled, (state, action) => {
        state.lastBillNo = action?.payload?.data?.data?.bill_number;
        state.lastInvoiceNo = action?.payload?.data?.data?.invoice_number;
      })
  },
});

export default invoiceSlice.reducer;
