import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  symbolList: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getPortfolioList = createAsyncThunk(
  "get-portfolio",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("portfolio/investment-list", {
        params: data,
      });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getPortfolioSymbolsList = createAsyncThunk(
  "get-portfolio-symbols",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("portfolio/symbols", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getPortfolioSymbolRefresh = createAsyncThunk(
  "get-refresh-symbols",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("portfolio/refresh-symbol", {
        params: data,
      });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getPortfolioInvestment = createAsyncThunk(
  "get-refresh-symbols",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(
        `portfolio/investment-view-all/${data.id}`,
        { params: data }
      );
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create-portfolio",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("portfolio/buy-sell-stock", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editPortfolio = createAsyncThunk(
  "edit-portfolio",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`portfolio/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update-portfolio",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/portfolio/investment/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete-portfolio",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("portfolio/investment-delete", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const stopLossTargetSet = createAsyncThunk(
  "update-portfolio",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(
        `/portfolio/stop-loss-target/${id}`,
        data
      );
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolioList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPortfolioList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.investmentList;
        state.perPage = action?.payload?.data?.data?.perPage;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getPortfolioList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      })
      .addCase(getPortfolioSymbolsList.fulfilled, (state, action) => {
        state.symbolList = action?.payload?.data?.data?.symbolList;
      });
  },
});

export default portfolioSlice.reducer;
