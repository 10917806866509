import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getExpenseTypeList = createAsyncThunk(
  "get-expense-type",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("expense-type/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createExpenseType = createAsyncThunk(
  "create-expense-type",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("expense-type", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editExpenseType = createAsyncThunk(
  "edit-expense-type",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`expense-type/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateExpenseType = createAsyncThunk(
  "update-expense-type",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/expense-type/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteExpenseType = createAsyncThunk(
  "delete-expense-type",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/expense-type/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const expenseTypeSlice = createSlice({
  name: "expenseType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExpenseTypeList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpenseTypeList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.expenses_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getExpenseTypeList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default expenseTypeSlice.reducer;
