import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Dashboard, PersonAddAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@jumbo/components/JumboTable";
import PageContainer from "@jumbo/components/PageContainer";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import { HOME, ON_ACCOUNT_ADD } from "@jumbo/constants/routes";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  deleteOnAccount,
  getOnAccountList,
} from "app/redux/slice/onAccountSlice";
import Div from "@jumbo/shared/Div";
import moment from "moment";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <PersonAddAlt />,
    label: "OnAccount",
  },
];

const OnAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const deleteDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onAccountData = useSelector((state) => state?.onAccount);
  const [page, setPage] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );

  const [permission, setPermission] = useState({
    createPermission: true,
    updatePermission: true,
    deletePermission: true,
  });

  const column = [
    {
      id: "transaction_date",
      label: "Date",
      render: (elm) => (
        <> {moment(elm?.transaction_date).format("DD/MM/YYYY")}</>
      ),
    },
    { id: "store_name", label: "Store Name" },
    { id: "amount", label: "Amount" },
    { id: "note", label: "note" },
    {
      label: "Actions",
      key: "actionIcon",
      sort: false,
      align: "right",
      render: (elm) => (
        <>
          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                deleteDisclosure.onOpen();
              }}
            >
              <DeleteIcon color={"error"} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const handleConfirm = () => {
    dispatch(deleteOnAccount(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          setDeleted(!deleted);
          deleteDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
  };

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      search: searchText.trim(),
      date,
    };
    dispatch(getOnAccountList(value));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, page, rowsPerPage, dispatch]);

  return (
    <PageContainer heading="OnAccount" breadcrumb={breadcrumbData}>
      <Paper className={classes?.filterContainer}>
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="date"
                    label={"Date"}
                    name="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    size="small"
                    onChange={(e) => setDate(e.target.value)}

                    // value={values?.fromDate}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setPage(0);
                      getData();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      <Paper>
        {permission.createPermission && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              startIcon={<Add />}
              className={classes?.buttonPadding}
              onClick={() => navigate(ON_ACCOUNT_ADD)}
            >
              Add
            </Button>
          </Box>
        )}

        <Table
          columns={column}
          rows={onAccountData.list}
          count={onAccountData.total}
          setPage={setPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </Paper>

      <DialogConfirm
        open={deleteDisclosure?.isOpen}
        title="Delete OnAccount"
        message="Are you sure you want to delete this onAccount ?"
        onConfirm={handleConfirm}
        onClose={deleteDisclosure.onClose}
      />
    </PageContainer>
  );
};

export default OnAccount;
