import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Dashboard, People } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@jumbo/components/JumboTable";
import PageContainer from "@jumbo/components/PageContainer";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  HOME,
  CUSTOMER_ADD,
  createCustomerEditRoute,
} from "@jumbo/constants/routes";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Div from "@jumbo/shared/Div";
import { userType } from "app/helpers/userType";
import { deleteCustomer, getCustomerList } from "app/redux/slice/customerSlice";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <People />,
    label: "Customer",
  },
];

const Customer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const deleteDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { isMA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const CustomerData = useSelector((state) => state?.customer);
  const [page, setPage] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );

  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });

  const getPermission = () => {
    if (isMA) {
      setPermission({
        createPermission: true,
        updatePermission: true,
        deletePermission: true,
      });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      permissionData?.length &&
        permissionData.forEach(function (num) {
          if (num?.name === "customer-create") {
            permissionValue.createPermission = true;
          }
          if (num?.name === "customer-delete") {
            permissionValue.deletePermission = true;
          }
          if (num?.name === "customer-update") {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const column = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone_no", label: "Mobile No." },
    {
      label: "Actions",
      key: "actionIcon",
      sort: false,
      align: "right",
      render: (elm) => (
        <>
          {permission.updatePermission && (
            <IconButton
              onClick={() => {
                navigate(createCustomerEditRoute(elm?.id));
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                deleteDisclosure.onOpen();
              }}
            >
              <DeleteIcon color={"error"} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const handleConfirm = () => {
    dispatch(deleteCustomer(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          setDeleted(!deleted);
          deleteDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
  };

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      search: searchText.trim(),
      store_id: localStorage.getItem("store_id"),
    };
    dispatch(getCustomerList(value));
  };

  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      getData();
    } else {
      setView(false);
      enqueueSnackbar("Please select store.", { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, page, rowsPerPage, dispatch]);

  return (
    <PageContainer heading="Customer" breadcrumb={breadcrumbData}>
      <Paper className={classes?.filterContainer}>
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setPage(0);
                      getData();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      {view && (
        <Paper>
          {permission.createPermission && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="outlined"
                startIcon={<Add />}
                className={classes?.buttonPadding}
                onClick={() => navigate(CUSTOMER_ADD)}
              >
                Add
              </Button>
            </Box>
          )}

          <Table
            columns={column}
            rows={CustomerData.list}
            count={CustomerData.total}
            setPage={setPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      )}

      <DialogConfirm
        open={deleteDisclosure?.isOpen}
        title="Delete Customer"
        message="Are you sure you want to delete this customer ?"
        onConfirm={handleConfirm}
        onClose={deleteDisclosure.onClose}
      />
    </PageContainer>
  );
};

export default Customer;
