import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import { Dashboard, Receipt, Add, Edit, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import PageContainer from "@jumbo/components/PageContainer";
import Table from "@jumbo/components/JumboTable";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import { BILL_ADD, HOME, createBillEditRoute } from "@jumbo/constants/routes";
import { deleteBill, getBillList } from "app/redux/slice/billSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { userType } from "app/helpers/userType";
import { getPartiList } from "app/redux/slice/partiSlice";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "20px",
  },
  dropDownContainer: {
    padding: "20px 12px 0px 12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <Receipt />,
    label: "Bill",
  },
];

const validationSchema = yup.object().shape({
  toDate: yup.date().when("fromDate", (fromDate, schema) => {
    if (fromDate) {
      return schema
        .required("To Date is required")
        .min(fromDate, "To Date cannot be before From Date");
    }
    return schema;
  }),
});

const Bill = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const downloadDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { isMA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const billData = useSelector((state) => state?.bill);
  const supplierData = useSelector((state) => state?.supplier);
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [supplier, setSupplier] = useState();
  const [view, setView] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );

  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });

  const getPermission = () => {
    if (isMA) {
      setPermission({
        createPermission: true,
        updatePermission: true,
        deletePermission: true,
      });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      permissionData?.length &&
        permissionData.forEach(function (num) {
          if (num?.name === "bill-create") {
            permissionValue.createPermission = true;
          }
          if (num?.name === "bill-delete") {
            permissionValue.deletePermission = true;
          }
          if (num?.name === "bill-update") {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const column = [
    {
      id: "date",
      label: "Date",
      render: (elm) => <> {moment(elm?.bill_date).format("DD/MM/YYYY")}</>,
    },
    { id: "bill_number", label: "Bill No" },
    { label: "Vendor", render: (elm) => elm?.supplier?.name },
    { id: "bill_amount", label: "Amount" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "pending_amount", label: "Pending Amount" },
    {
      label: "Actions",
      key: "actionIcon",
      sort: false,
      render: (elm) => (
        <>
          {permission.updatePermission && (
            <IconButton
              onClick={() => {
                navigate(createBillEditRoute(elm?.id));
              }}
            >
              <Edit />
            </IconButton>
          )}
          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm?.id);
                deleteDisclosure.onOpen();
              }}
            >
              <Delete color={"error"} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      search: values?.search?.trim(),
      from_date: values?.fromDate,
      to_date: values?.toDate,
    };
    if (supplier) value.supplier_id = supplier.id;
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    )
      value.store_id = localStorage.getItem("store_id");

    dispatch(getBillList(value));
  };
  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      search: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: getData,
  });
  const handleConfirm = () => {
    dispatch(deleteBill(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          getData();
          deleteDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      getData();
    } else {
      setView(false);
      enqueueSnackbar("Please select store.", { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch]);
  useEffect(() => {
    dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
  }, [dispatch]);
  const { values, handleSubmit } = formik;
  return (
    <PageContainer heading="Bill" breadcrumb={breadcrumbData}>
      <Paper className={classes?.filterContainer}>
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField
                        fullWidth
                        size="small"
                        name="search"
                        label="Search"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboAutoComplete
                        options={supplierData.list ?? []}
                        label="Vendor"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="supplier"
                        onChange={(evt, value) => setSupplier(value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"From Date"}
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"To Date"}
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.toDate}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setPage(0);
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      {view && (
        <Paper>
          {permission.createPermission && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="outlined"
                startIcon={<Add />}
                className={classes?.buttonPadding}
                onClick={() => navigate(BILL_ADD)}
              >
                Add
              </Button>
            </Box>
          )}
          <Table
            columns={column}
            rows={billData.list}
            count={billData.total}
            setPage={setPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            isBill
          />
        </Paper>
      )}

      <DialogConfirm
        open={downloadDisclosure?.isOpen}
        title="Download Bill"
        message="Are you sure you want to download this Bill ?"
        onConfirm={() => downloadDisclosure.onClose()}
        onClose={() => downloadDisclosure.onClose()}
      />

      <DialogConfirm
        open={deleteDisclosure?.isOpen}
        title="Delete Bill"
        message="Are you sure you want to delete this Bill ?"
        onConfirm={handleConfirm}
        onClose={deleteDisclosure.onClose}
      />
    </PageContainer>
  );
};

export default Bill;
