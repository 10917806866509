import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useCookies } from "react-cookie";
const url = process.env.REACT_APP_OM_URL_CHECK;
const urlLogin = process.env.REACT_APP_OM_URL;

const AuthUserDropdown = () => {
  const { theme } = useJumboTheme();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "laravel_session",
    "XSRF-TOKEN",
  ]);

  const onLogout = () => {
    // if (authUser?.jwt_token) {
    //   fetch(`${url}api/logout`, {
    //     method: "Post",
    //     headers: {
    //       Authorization: `Bearer ${authUser?.jwt_token}`,
    //     },
    //   })
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error("Network response was not ok");
    //       }
    //       return response.json();
    //     })
    //     .then((data) => {
    //       if (data?.success) {
    //         localStorage.clear();
    //         // document.cookie =
    //         //   "cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //         removeCookie("access_token", { path: "/" });
    //         removeCookie("laravel_session", { path: "/" });
    //         removeCookie("XSRF-TOKEN", { path: "/" });

    //         // Optional: Clear multiple cookies
    //         Object.keys(cookies).forEach((cookieName) =>
    //           removeCookie(cookieName, { path: "/" })
    //         );

    //         document.cookie.split(";").forEach(cookie => {
    //           const cookieName = cookie.split("=")[0].trim();
    //           document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    //         });
    //         window.location.href = `${urlLogin}login`;
    //       }
    //     })
    //     .catch((error) => {
    //       // Handle any errors
    //       console.error("There was a problem with the fetch operation:", error);
    //     });
    // }
    window.location.href = `${urlLogin}login`;
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={authUser?.profile_pic}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={authUser?.profile_pic}
            alt={authUser?.name}
            sx={{ width: 60, height: 60, mb: 2 }}
          />
          <Typography variant={"h5"}>{authUser?.name}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
