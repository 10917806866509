import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper } from "@mui/material";
import { Add, Lock, Dashboard } from "@mui/icons-material";

import Div from "@jumbo/shared/Div";
import Table from "@jumbo/components/JumboTable";
import PageContainer from "@jumbo/components/PageContainer";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import { HOME } from "@jumbo/constants/routes";
import UpdateAddExpenseType from "../addAndUpdate";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteExpenseType,
  getExpenseTypeList,
} from "app/redux/slice/expenseTypeSlice";
import { userType } from "app/helpers/userType";

const ExpenseType = () => {
  const expenseTypeDisclosure = useDisclosure();
  const alertDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isMA } = userType();
  const expenseTypeData = useSelector((state) => state?.expenseType);
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });

  const getPermission = () => {
    if (isMA) {
      setPermission({
        createPermission: true,
        updatePermission: true,
        deletePermission: true,
      });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      permissionData?.length &&
        permissionData.forEach(function (num) {
          if (num?.name === "expenseType-create") {
            permissionValue.createPermission = true;
          }
          if (num?.name === "expenseType-delete") {
            permissionValue.deletePermission = true;
          }
          if (num?.name === "expenseType-update") {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "actions",
      label: "Actions",
      sort: false,
      render: (elm) => (
        <>
          {permission.updatePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                expenseTypeDisclosure.stageAction(elm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                alertDisclosure.onOpen();
              }}
            >
              <DeleteIcon color={"error"} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const breadcrumb = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      icon: <Lock />,
      label: "Expense Type",
    },
  ];

  const handleConfirm = () => {
    dispatch(deleteExpenseType(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          getData();
          setId(false);
          alertDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
  };
  const getData = () => {
    const value = { page: page + 1, per_page: rowsPerPage };
    dispatch(getExpenseTypeList(value));
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch]);

  return (
    <PageContainer heading="Expense Type" breadcrumb={breadcrumb}>
      <Paper>
        {permission.createPermission && (
          <Div
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mr: 2 }}
          >
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={expenseTypeDisclosure.onOpen}
            >
              Add ExpenseType
            </Button>
          </Div>
        )}
        <Table
          columns={columns}
          rows={expenseTypeData.list}
          count={expenseTypeData.total}
          setPage={setPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />

        <DialogConfirm
          open={alertDisclosure?.isOpen}
          title="Delete ExpenseType"
          message="Are you sure you want to delete this expense type?"
          onConfirm={handleConfirm}
          onClose={alertDisclosure.onClose}
        />

        <UpdateAddExpenseType
          isDialogOpen={expenseTypeDisclosure.isOpen}
          expenseTypeDisclosure={expenseTypeDisclosure}
          id={id}
          setId={setId}
          getData={getData}
        />
      </Paper>
    </PageContainer>
  );
};

export default ExpenseType;
