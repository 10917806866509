import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Dashboard, Storage } from "@mui/icons-material";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";

import { Button, Card, CardContent, Grid, Paper } from "@mui/material";

import PageContainer from "@jumbo/components/PageContainer";
import Table from "@jumbo/components/JumboTable";
import { HOME } from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getLogList } from "app/redux/slice/logSlice";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { getPartiList } from "app/redux/slice/partiSlice";
import { getCustomerList } from "app/redux/slice/customerSlice";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  balanceContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dropDownContainer: {
    padding: "20px 12px 0px 12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});
const validationSchema = yup.object().shape({
  toDate: yup
    .date()
    .nullable()
    .when("fromDate", (fromDate, schema) => {
      if (fromDate) {
        return schema
          .required("To Date is required")
          .min(fromDate, "To Date cannot be before From Date");
      }
      return schema;
    }),
});
const Logs = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const logData = useSelector((state) => state?.log);
  const supplierData = useSelector((state) => state?.supplier);
  const customerData = useSelector((state) => state?.customer);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      icon: <Storage />,
      label: "Logs",
    },
  ];

  const column = [
    {
      id: "transactionDate",
      label: "Transaction Date",
      render: (elm) => (
        <> {moment(elm?.transactionDate).format("DD/MM/YYYY")}</>
      ),
    },
    { id: "module_name", label: "Module Name" },
    {
      id: "supplier_name",
      label: "Vendor",
      render: (elm) => (elm?.supplier_name ? elm?.supplier_name : "-"),
    },
    {
      id: "customer_name",
      label: "Customer",
      render: (elm) => (elm?.customer_name ? elm?.customer_name : "-"),
    },
    {
      id: "perform_by_name",
      label: "Perform By",
      render: (elm) => (elm?.perform_by_name ? elm?.perform_by_name : "-"),
    },
    {
      id: "bill_number",
      label: "Bill Number",
      render: (elm) => (elm?.bill_number ? elm?.bill_number : "-"),
    },
    {
      id: "invoice_number",
      label: "Invoice Number",
      render: (elm) => (elm?.invoice_number ? elm?.invoice_number : "-"),
    },
    {
      id: "cash_sell_number",
      label: "Slip Number",
      render: (elm) => (elm?.cash_sell_number ? elm?.cash_sell_number : "-"),
    },
    {
      id: "amount",
      label: "Amount",
      render: (elm) => (elm?.amount ? elm?.amount : "-"),
    },
    { id: "action", label: "Action" },
  ];

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      from_date: values?.fromDate,
      to_date: values?.toDate,
      search: values?.search?.trim(),
      supplier_id: values?.supplier_id?.id,
      customer_id: values?.customer_id?.id,
    };
    dispatch(getLogList(value));
  };

  const formik = useFormik({
    initialValues: {
      fromDate: null,
      toDate: null,
      search: "",
      supplier_id: null,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: getData,
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch, pathname]);
  useEffect(() => {
    dispatch(getPartiList({}));
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      dispatch(getCustomerList({ store_id: localStorage.getItem("store_id") }));
    } else {
      dispatch(getCustomerList({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetForm();
    dispatch(
      getLogList({
        page: page + 1,
        per_page: rowsPerPage,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const { values, setFieldValue, handleSubmit, resetForm } = formik;

  return (
    <PageContainer heading={"Logs"} breadcrumb={breadcrumbData}>
      <Paper className={classes?.filterContainer}>
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        fullWidth
                        size="small"
                        name="search"
                        label="Search"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"From Date"}
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        size="small"
                        value={values?.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"To Date"}
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        size="small"
                        value={values?.toDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboAutoComplete
                        options={supplierData.list}
                        label="Vendor"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="supplier_id"
                        onChange={(evt, value) =>
                          setFieldValue("supplier_id", value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboAutoComplete
                        options={customerData.list ?? []}
                        label="Customer"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="customer_id"
                        onChange={(evt, value) =>
                          setFieldValue("customer_id", value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setPage(0);
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      <Paper>
        <Table
          columns={column}
          rows={logData.list}
          count={logData.total}
          setPage={setPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
    </PageContainer>
  );
};

export default Logs;
