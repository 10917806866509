import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";

//todo: to see how to define prop-types for this component

const JumboTextField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const onKeyPressNumber = (evt) => {
    if (props.type === "number") {
      let needPrevent = false;
      if (evt.key === "-") {
        needPrevent = true;
      } else if (evt.key === ".") {
        if (field.value.toString().includes(".")) {
          needPrevent = true;
        }
      } else if (!/[0-9]/.test(evt.key)) {
        needPrevent = true;
      }
      if (needPrevent) {
        evt.preventDefault();
      }
    }
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      // value={props.value}
      // onChange={props.onChange}
      onKeyPress={onKeyPressNumber}
    />
  );
};

export default JumboTextField;
