import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getPartiList = createAsyncThunk(
  "get-supplier",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("supplier/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createParti = createAsyncThunk(
  "create-supplier",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("supplier", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editParti = createAsyncThunk(
  "edit-supplier",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`supplier/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateParti = createAsyncThunk(
  "update-supplier",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/supplier/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteParti = createAsyncThunk(
  "delete-supplier",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/supplier/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const partiSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartiList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartiList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.supplier_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getPartiList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default partiSlice.reducer;
