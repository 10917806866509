import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  useMediaQuery,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { useDispatch, useSelector } from "react-redux";
import { getStoreList } from "app/redux/slice/storeSlice";
import { userType } from "app/helpers/userType";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false);
  const { headerTheme } = useJumboHeaderTheme();
  const dispatch = useDispatch();
  const { isMA } = userType();

  const showDropdownSearch = useMediaQuery("(max-width:575px)");
  const storeData = useSelector((state) => state?.store);
  const [storeId, setStoreId] = useState(localStorage.getItem("store_id") ?? 0);
  useEffect(() => {
    dispatch(getStoreList());
  }, [dispatch]);
  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />
      )}
      {showDropdownSearch && (
        <Slide in={dropdownSearchVisibility}>
          <Div
            sx={{
              zIndex: 5,
              left: 0,
              right: 0,
              position: "absolute",
              height: "100%",
            }}
          >
            <SearchGlobal
              sx={{
                maxWidth: "none",
                height: "100%",
                display: "flex",

                "& .MuiInputBase-root": {
                  flex: 1,
                  borderRadius: 0,
                  background: (theme) => theme.palette.background.default,
                },
                "& .MuiInputBase-input": {
                  pr: 6,
                },
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                top: "50%",
                color: "inherit",
                transform: "translateY(-50%)",
              }}
              onClick={() => setDropdownSearchVisibility(false)}
            >
              <CloseIcon />
            </IconButton>
          </Div>
        </Slide>
      )}
      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: "auto" }}
      >
        {isMA && (
          <FormControl fullWidth size="small" sx={{ width: "150px" }}>
            <InputLabel size="small" id="store">
              Select Store
            </InputLabel>
            <Select
              labelId="store"
              id="store-select"
              value={storeId}
              label="Select Store"
              size="small"
              // disabled={localStorage.getItem('IsStoreSet') === '0' ? false : true}
              onChange={(e) => {
                localStorage.setItem("store_id", e.target.value);
                // setCookie('company_id', e.target.value);
                setStoreId(e.target.value);
                window.location.reload()
                // window.location.pathname === "/"
                //   ? window.location.reload()
                //   : navigate("/");
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {storeData.list.map((val, i) => (
                <MenuItem key={i} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <AuthUserDropdown />
      </Stack>
    </React.Fragment>
  );
};

export default Header;
