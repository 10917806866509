import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  loading: false,
};

export const getLogList = createAsyncThunk(
  "get-log",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("accounts-log/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLogList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLogList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.accounts_log_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getLogList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default logSlice.reducer;
