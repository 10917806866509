import React from "react";
import PropTypes from "prop-types";

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

const TableHeading = ({ columns, onRequestSort, orderBy, order }) => {
  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(
          (headCell) =>
            !headCell.hide && (
              <TableCell
                key={headCell?.id}
                align={headCell?.numeric ? "right" : "left"}
                padding={headCell?.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell?.sort === true ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell?.label
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

TableHeading.propTypes = {
  columns: PropTypes.array,
  multiSelect: PropTypes.bool,
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(["asc", "desc"]),
  onRequestSort: PropTypes.func,
};

TableHeading.defaultProps = {
  columns: [],
  multiSelect: false,
  onRequestSort: () => {},
};

export default TableHeading;
