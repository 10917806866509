import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  loading: false,
};

export const getVendorList = createAsyncThunk(
  "get-vendor",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("vendor/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendorList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.vendor_list;
      })
      .addCase(getVendorList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
      });
  },
});

export default vendorSlice.reducer;
